<template>
<div id="">
  <v-container grid-list-md>
    <v-card class="hidden-md-and-down">
      <v-layout  wrap align-center>
        <v-flex xs3 align-center class="pa-2">
          <v-img :src="thumbnail.url" :alt="'My Room Abroad Room: '+room.general.title + ', '+thumbnail.text">
          </v-img>
          <!-- <a href="#"> {{request.id}}</a> -->
          <!-- <span v-if="user.roleValue>80"><a href="#"></a> </span>  -->
        </v-flex>
        <!-- <v-btn color="primary" @click="checkRequests()" >checkDates</v-btn> -->
        <v-flex xs4>
          <v-layout  wrap align-center>
            <v-flex xs12 class="headline">
              {{room.general.fullRoomNumber}}
            </v-flex>
            <v-flex xs12 class="price">
              {{request.price}} NT$
            </v-flex>
            <v-flex xs12>
              <v-layout  wrap align-center class="dates">

                <v-flex xs0 class="">
                  {{$t("userRequest.mid")}}<br>
                  <span class="subheading">{{mid}}</span>
                </v-flex>
                <v-flex xs0>
                  <v-icon large>arrow_forward</v-icon>
                </v-flex>
                <v-flex xs0 class="">
                  {{$t("userRequest.mod")}}<br>
                  <span class="subheading">{{mod}}</span>

                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12>
              <div class="couple" v-if="tenant.couple=='couple'">
                {{$t("userRequest.couple")}}
              </div>
              <div class="couple single" v-if="tenant.couple=='single'">
                {{$t("requestsInbox.onePerson")}}
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs1>
          <v-layout  wrap>
            <v-flex xs12 class="capitalize">
              {{occupation}}
            </v-flex>
            <v-flex xs12>
              {{tenant.school}}
            </v-flex>
            <v-flex xs12 class="capitalize">
              {{tenant.gender}}<span>, </span>{{age}}
            </v-flex>
            <v-flex xs12>
              {{nationality}}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs2>
          <div class="deadline-time caption text-xs-center" :class="{rush:rush, day:day}">
            <Countdown :end="end" v-if="end!=''&& request.status=='Requested'"></Countdown>
          </div>
        </v-flex>
        <v-flex xs2>
          <v-layout  wrap>
            <v-flex xs12 class="text-xs-center">

              <v-btn color="primary " @click="checkRequests" :disabled="disabled||working">{{$t("requestsInbox.accept")}}</v-btn>
              <div class="" v-if="working">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              </div>
            </v-flex>
            <v-flex xs12 class="text-xs-center">
              <!-- <v-btn color="primary" @click="changeWorking">text</v-btn> -->

              <v-btn color="error" @click="declineDialog=true" :disabled="disabled||working">{{$t("requestsInbox.decline")}}</v-btn>
            </v-flex>
            <v-flex xs12 class="text-xs-center">
              <v-btn flat @click="show = !show">
                {{$t("requestsInbox.details")}} <v-icon>{{ !show ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-slide-y-transition>
        <v-card-text v-show="show">
          <v-layout  align-center wrap>
            <v-flex xs12 sm5 class="subheading">

            {{$t("requestsInbox.realDates")}}
          </v-flex>
          <v-flex xs12 md4 sm6>

            <v-layout  wrap align-center class="dates">

              <v-flex xs0 class="">
                {{$t("userRequest.mid")}}:<br>
                <span class="subheading">{{realMid}}</span>
              </v-flex>
              <v-flex xs0>
                <v-icon large>arrow_forward</v-icon>
              </v-flex>
              <v-flex xs0 class="">
                {{$t("userRequest.mod")}}:<br>
                <span class="subheading">{{realMod}}</span>

              </v-flex>
            </v-layout>
          </v-flex>
          </v-layout>
          <v-layout  wrap>
            <v-flex xs12 class="subheading">
              {{$t("requestsInbox.aboutTenant")}}
            </v-flex>
            <v-flex xs12 class="justify">
              {{tenant.about}}
            </v-flex>

          </v-layout>
        </v-card-text>
      </v-slide-y-transition>
    </v-card>
    <v-card class="hidden-lg-and-up">
      <v-card-title primary-title>
        <v-flex xs12 class="headline text-xs-center pa-2">
          {{room.general.fullRoomNumber}}
        </v-flex>
        <v-flex xs5 class="text-xs-center pa-1">
          {{$t("userRequest.mid")}}<br>
          <span class="headline">{{mid}}</span>
        </v-flex>
        <v-flex xs2 class="text-xs-center pa-1">
          <v-icon large>arrow_forward</v-icon>
        </v-flex>
        <v-flex xs5 class="text-xs-center pa-1">
          {{$t("userRequest.mod")}}<br>
          <span class="headline">{{mod}}</span>
        </v-flex>
        <v-flex xs12 class="pa-2 text-xs-center">

          <div class="deadline-time caption" :class="{rush:rush, day:day}">
            <Countdown :end="end" v-if="end!=''&& request.status=='Requested'"></Countdown>
            <span v-if="request.status=='Past'"></span>
          </div>
        </v-flex>
      </v-card-title>
      <v-card-actions>

        <v-btn color="primary " @click="checkRequests" :disabled="disabled || working">{{$t("requestsInbox.accept")}}</v-btn>
        <div class="" v-if="working">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
        <v-btn color="error" @click="declineDialog=true" :disabled="disabled || working">{{$t("requestsInbox.decline")}}</v-btn>
        <v-spacer></v-spacer>
        <v-btn flat icon @click="show = !show">
          <v-icon>{{ !show ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-slide-y-transition>
        <v-card-text v-show="show">
          <v-layout  wrap>
            <v-flex xs6>
              <v-layout  wrap>
              <v-flex xs12>
                {{request.price}} NT$
              </v-flex>
              <v-flex xs12>
                <div class="couple" v-if="tenant.couple=='couple'">
                  {{$t("userRequest.couple")}}
                </div>
                <div class="couple single" v-if="tenant.couple=='single'">
                  {{$t("requestsInbox.onePerson")}}
                </div>
              </v-flex>

            </v-layout>
            </v-flex>
            <v-flex xs6>
              <v-layout  wrap>
                <v-flex xs12 class="capitalize">
                  {{tenant.gender}}<span>, </span>{{age}}
                </v-flex>
                <v-flex xs12>
                  {{nationality}}
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 class="capitalize">
              {{occupation}} @ {{tenant.school}}
            </v-flex>
            <v-layout  align-center wrap>
              <v-flex xs12 class="subheading">

                {{$t("requestsInbox.realDates")}}
            </v-flex>
            <v-flex xs12>

              <v-layout  wrap align-center class="dates">

                <v-flex xs0 class="">
                  {{$t("userRequest.mid")}}:<br>
                  <span class="">{{realMid}}</span>
                </v-flex>
                <v-flex xs0>
                  <v-icon large>arrow_forward</v-icon>
                </v-flex>
                <v-flex xs0 class="">
                  {{$t("userRequest.mod")}}:<br>
                  <span class="">{{realMod}}</span>

                </v-flex>
              </v-layout>
            </v-flex>
            </v-layout>
            <v-flex xs12 justify>
              <v-layout  wrap>
                <v-flex xs12 class="subheading">{{$t("requestsInbox.aboutTenant")}}</v-flex>
                <v-flex xs12 class="justify">

                  {{tenant.about}}
                </v-flex>

              </v-layout>

            </v-flex>
          </v-layout>
        </v-card-text>
      </v-slide-y-transition>
    </v-card>
  </v-container>
  <v-dialog v-model="showAlert" persistent max-width="300">
    <v-card>
      <v-card-title class="headline" justify-center >
        <div class="text-xs-center " style="width:100%">
          <v-icon color="error">warning</v-icon> {{$t("requestsInbox.warning")}} <v-icon color="error">warning</v-icon>
        </div>
      </v-card-title>
      <v-card-text>
      {{$t("requestsInbox.warnText")}} </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" text :disabled="disabled" @click="acceptRequest">{{$t("requestsInbox.acceptBooking")}}</v-btn>
        <v-btn color="primary" text @click="back">{{$t("uploads.back")}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <DeclineDialog :declineDialog='declineDialog' @changeDialog="declineDialog=$event" @decline="declineRequest" :allRooms="rooms"></DeclineDialog>
</div>
</template>
<script>
import AWSSES from '../../aws-ses'
import axios from 'axios'
import Countdown from 'vuejs-countdown'
import moment from 'moment'
import DeclineDialog from './DeclineDialog.vue'
import countries from "i18n-iso-countries"

export default {
  name: "BookingRequestItem",
  props: ['request', 'index','working','rooms'],
  data: () => ({
    transactionCost:.028,
    serviceFee:.2,
    age: "",
    room: {
      billing: {},
      visual: {},
      general: {}
    },
    apartment: {
      general: {}
    },
    declineDialog:false,
    mid: "",
    showAlert:false,
    mod: "",
    show: false,
    end: "",
    db: "",
    thumbnail: "https://res.cloudinary.com/my-room-abroad/image/upload/w_600/Logo/mra-no-photos-placeholder.jpg",
    tenant: {},
    linkToRequest: {},
    disabled: false,
    rush: false,
    day: false,
    admin: {
      name: 'Admin',
      email: "hello@myroomabroad.com"
    }
  }),

  methods: {
    changeWorking(){
      if (this.working==false){
        this.$emit('update:working',true)
      } else {
        this.$emit('update:working',false)
      }

    },
    back(){
      this.disabled=false
      this.showAlert=false
    },
    checkCoupons(){
      if (this.request.hasOwnProperty('coupon') && this.request.coupon!="" && this.request.hasOwnProperty('discount') && this.request.discount!=0){
        axios.get('rCoupons.json?orderBy="name"&equalTo="'+this.request.coupon+'"')
        .then(res=>{
          if (Object.keys(res.data).length >= 0){
            for (var el in res.data) {
              if (res.data.hasOwnProperty(el)) {
                if (res.data[el].sUse==true){
                  axios.patch('rCoupons/'+el+'.json?auth=' + this.$store.state.auth.idToken, {used:false})
                }
              }
            }
          }

        })
      } else {
        return
      }
    },
    declineRequest(payload) {
      let vThis=this
      this.disabled = true
      this.checkCoupons()
      // console.log('YOU HAVE DECLINED')
      delete this.request['.key']
      this.request.status = "Declined"
      this.request.confirmTimestamp = moment().format()
      this.request.reason=payload.reason
      this.request.suggest=payload.suggest
      if (payload.reason=='dates'){
        if (payload.mid==undefined||payload.mid==""){
          payload.mid=this.request.dates.mid
        }
        if (payload.mod==undefined||payload.mod==""){
          payload.mod=this.request.dates.mod
        }
        this.request.sMid=payload.mid
        this.request.sMod=payload.mod
      }
      if (payload.nextAv){
        axios.patch(vThis.db + vThis.request.listingId + '/general.json' + '?auth=' + vThis.$store.state.auth.idToken, {
          nextAvailability: payload.nextAv
        })
        .catch(err=>{
          console.log('error: ',err);
        })
      }
      console.log(this.request);
      axios.patch('bookingRequests/' + vThis.request.id + '.json?auth=' + this.$store.state.auth.idToken, vThis.request)
        .then(() => {
          dataLayer.push({
            'event':'declineRequest',
          })

          vThis.$emit('update:working',false)
          let payload2 = {
            request: this.request,
            index: this.index,
          }
          this.$emit('decline', payload2)
          // this.disabled = false
          axios.get('users/' + this.request.landlordId + '.json')
          .then(res => {
            if (res.data.sendChinese){
              AWSSES.emailing(res.data, 'ZHLLBookingRequestDeclined')
            } else {
              AWSSES.emailing(res.data, 'LLBookingRequestDeclined')
            }

          })
          .catch(err=>{
            console.log('error: ',err);
          })
          axios.get('users/' + this.request.tenantId + '.json')
          .then(res => {
            let info={
              tenant: res.data.name,
              landlord: "Your Landlord",
              mid: moment(payload.mid).format("YYYY-MM-DD"),
              mod: moment(payload.mod).format("YYYY-MM-DD"),
              amount:null,
              comment:null,
              room:this.room.general.fullRoomNumber
            }

            // AWSSES.emailingInfo(this.admin,info, 'ABookingRequestDeclined')
            if (payload.suggest!=null && payload.suggest!="" && payload.suggest.includes('/')){
              // console.log("There is  a suggestion");
              AWSSES.emailingInfo(res.data, info, 'TBookingRequestDeclinedSuggest')
            } else if (payload.mid != null && payload.mid!=""){
              // console.log("There is  a date suggestion");
              AWSSES.emailingInfo(res.data, info, 'TBookingRequestDeclinedDates')
            } else {
              // console.log('there is no suggestion');
              try {
                axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/sendDeclineEmail',this.request)
              } catch (e) {
                console.log(e);
                AWSSES.emailingInfo(res.data, info, 'TBookingRequestDeclined')
              }
            }
          })
          .catch(err=>{
            console.log('error: ',err);
          })

        })
        .catch(err=>{
          console.log('error: ',err);
        })
    },

    createFapiou(invoice,user){
      let data={
          relateNumber:invoice.invoiceNumber,
          businessId:user.businessId,
          name:user.name,
          userId:user.id,
          email:user.email,
          amount:invoice.amount,
          itemName:"My Room Abroad Service Fee",
          type:"B2C",
          address:""
      }
      axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/fapiou2', data, {
        headers: {
          'Content-Type': 'text/plain'
        }
      })
      .then(res => {
        // console.log('Response data: ', res.data);
        console.log('SUCCESS: ',res.data);
        let fapiouNumber=this.getQueryVariable('InvoiceNumber', res.data)
        if (fapiouNumber!=undefined  && fapiouNumber!=""){
          axios.post('fapiou.json?auth='+this.$store.state.auth.idToken,{number:fapiouNumber, ts:Math.round(moment().format('X')).toString(), user:user.id})
        } else {
          console.log('no fapiou created for ');
          this.failedFapiou(data,user)
        }
      })
      .catch(err=>{
        console.log('ERROR', err);
        this.failedFapiou(data,user)
      })
    },
    failedFapiou(data,user){
      axios.post('failedFapiou.json?auth='+this.$store.state.auth.idToken,data)


    },

    getQueryVariable(variable, query) {
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        console.log('Query variable %s not found', variable);
    },
    checkRequests(){
      this.disabled = true
      this.$emit('update:working',true)
      axios.get('/completedOrders.json?orderBy="listingId"&equalTo="'+this.request.listingId+'"')
      .then(res=>{
        let orders=res.data
        console.log(orders);
        for (var id in orders) {
          if (orders.hasOwnProperty(id)) {
            // console.log(this.);
            if ((moment(this.request.dates.mid).isBetween(moment(orders[id].dates.mid), moment(orders[id].dates.mod)) || moment(this.request.dates.mod).isBetween(moment(orders[id].dates.mid), moment(orders[id].dates.mod))) &&  orders[id].status.toLowerCase()!="canceled"){
              console.log(orders[id], orders[id].status.toLowerCase()!='canceled');
              this.showAlert=true
              this.disabled = false
              this.$emit('update:working',false)
              return
            }
          }
        }
        // alert('complete request')
        this.acceptRequest()
      })


    },
    acceptRequest() {
      const vThis = this
      let oneDone=false
      let twoDone=false
      this.disabled = true
      // console.log('YOU HAVE ACCEPTED')
      this.request.status = "Accepted"
      this.$emit('update:working',true)
      // vThis.$emit('switchLoading', true)
      vThis.request.fullRoomNumber=this.room.general.fullRoomNumber
      vThis.request.confirmTimestamp = moment().format()
      // console.log('linkL ','users/' + this.request.landlordId + '.json');
      axios.get('users/' + this.request.landlordId + '.json')
        .then(res => {
          let landlord=res.data
          vThis.request.landlord = {}
          vThis.request.landlord.name = res.data.name
          vThis.request.landlord.userName = res.data.userName
          vThis.request.landlord.email = res.data.email
          // console.log('landlord:',landlord)
          // console.log(vThis.request.landlord)
          axios.get('/users/' + vThis.request.tenantId + ".json")
            .then(res => {
              let tenant=res.data
              tenant.id=vThis.request.tenantId
              vThis.request.tenant={}
              vThis.request.tenant.email = res.data.email
              vThis.request.tenant.userName= res.data.userName
              vThis.request.tenant.name = res.data.name
              vThis.request.tenant.nationality = res.data.nationality
              // console.log(vThis.request.tenant)
              // console.log('tenant:',tenant)
              if (tenant.card!=undefined){
                delete vThis.request['.key']
                console.log(vThis.request);
                // let crData=JSON.stringify(vThis.request)/
                console.log(vThis.request);
                axios.post('/completedOrders.json?auth=' + vThis.$store.state.auth.idToken, vThis.request)
                  .then(res => {
                    // console.log(res)
                    let totals=this.calcTotal(vThis.request)
                    console.log(totals);
                    dataLayer.push({
                      'event':'acceptRequest',
                      'margin':parseInt(totals.commission)
                    })
                    vThis.request.orderId = res.data.name
                    if (tenant.card!=undefined){
                      let info={
                        "card_key": tenant.card.card_key,
                        "card_token": tenant.card.card_token,
                        "comment": "First Rent "+ vThis.request.fullRoomNumber,
                        "amount": totals.total
                      }
                      let fapiouInfo={
                        invoiceNumber:'MRA'+Math.round(moment().format('X')).toString()+tenant.userName.substring(0,3).toUpperCase(),
                        amount: totals.commission,
                      }
                      console.log('info: for card: ', info);
                      console.log('Request: ', vThis.request);
                      try{
                        this.createFapiou(fapiouInfo,tenant)
                      }
                      catch(err){
                        console.error(err);
                      }
                      // console.log('the order:',vThis.request);
                      // axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/sandboxPayByToken', info, {
                      axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/payByToken', info, {
                        headers: {
                          'Content-Type': 'text/plain'
                        }
                      })
                      .then(res=>{
                        // console.log('The res.data from your own function',res.data);
                        if (res.data.status==0 ){

                          vThis.successfullPayment(vThis.request, tenant, landlord)
                        } else {
                          vThis.failedPayment(vThis.request,res.data)
                        }
                        let payload = {
                          request: vThis.request,
                          index: vThis.index,
                        }
                        console.log('accept');
                        oneDone=true
                        if (oneDone==true && twoDone==true){
                          vThis.executeEmit(payload)
                        }

                      })

                    }
                    let info={
                      tenant: vThis.request.tenant.name,
                      landlord: vThis.request.landlord.userName,
                      mid: moment(vThis.request.dates.mid).format("YYYY-MM-DD"),
                      mod: moment(vThis.request.dates.mod).format("YYYY-MM-DD"),
                      amount:null,
                      comment:null,
                      room:vThis.room.general.fullRoomNumber
                    }
                    if (landlord.sendChinese){
                      AWSSES.emailingInfo(vThis.request.landlord, info, 'ZHLLBookingRequestConfirmed')
                    } else {
                      AWSSES.emailingInfo(vThis.request.landlord, info, 'LLBookingRequestConfirmed')
                    }
                    // AWSSES.emailingInfo(vThis.admin, info, 'ABookingRequestConfirmed')
                    AWSSES.emailingInfo(vThis.request.tenant, info, 'TBookingRequestConfirmed')
                    // let brData=JSON.stringify(vThis.request)
                    axios.patch('/bookingRequests/' + vThis.request.id + '.json' + '?auth=' + vThis.$store.state.auth.idToken, vThis.request)
                    .catch(err=>{
                      console.log('error: ',err);
                    })
                    axios.patch(vThis.db + vThis.request.listingId + '/general.json' + '?auth=' + vThis.$store.state.auth.idToken, {
                      nextAvailability: vThis.request.dates.mod,
                      previousNextAvailability: vThis.request.oldNextAv
                    })
                    .catch(err=>{
                      console.log('error: ',err);
                    })
                    //Check if you need its septBooked and SummerAv
                    if (landlord.acceptSummer==true && moment(vThis.request.oldNextAv).isBefore(moment().month('August').date(1)) && moment(vThis.request.dates.mid).isAfter(moment().month('July').date(31))){
                      axios.patch(vThis.db + vThis.request.listingId + '.json' + '?auth=' + vThis.$store.state.auth.idToken, {
                        septBooked:true,
                        summerBooked:false
                      })
                    } else if (landlord.acceptSummer==true && moment(vThis.request.oldNextAv).isAfter(moment().month('November').date(1)) && moment(vThis.request.dates.mid).isBefore(moment().month('August').date(1))){
                      axios.patch(vThis.db + vThis.request.listingId + '.json' + '?auth=' + vThis.$store.state.auth.idToken, {
                        summerBooked:true
                      })
                    }
                    axios.get(vThis.db + vThis.request.listingId + '/tenants/tenants.json')
                      .then(res => {
                        let tenants=[]
                        if(res.data){
                          tenants = res.data
                        }
                        // console.log()
                        let newTenant = {
                          completedOrder: vThis.request.orderId,
                          dateAdded: new Date(),
                          moveInDate: vThis.request.dates.mid,
                          moveOutDate: vThis.request.dates.mod,
                          nationality: vThis.tenant.nationality,
                          occupation: vThis.tenant.occupation,
                          gender: vThis.tenant.gender,
                          school: vThis.tenant.school,
                          userId: vThis.request.tenantId,
                        }
                        tenants.push(newTenant)
                        let payload = {
                          request: vThis.request,
                          index: vThis.index,
                        }
                        // console.log('accept');
                        twoDone=true
                        if (oneDone==true && twoDone==true){
                          vThis.executeEmit(payload)
                        }
                        axios.patch(vThis.db + vThis.request.listingId + '/tenants.json' + '?auth=' + vThis.$store.state.auth.idToken, {
                          tenants: tenants
                        })
                        // .then(()=>{
                          // console.log('switchloading');
                          // vThis.$emit('loadingFalse')
                        // })
                        .catch(err=>{
                          console.log('error: ',err);
                          vThis.$emit('switchLoading', false)
                        })

                      })
                      .catch(err=>{
                        console.log('error: ',err);
                        vThis.$emit('switchLoading',false)
                      })

                  })
                  .catch(err=>{
                    this.$emit('update:working',false)
                    this.disabled=false
                    console.log('error: ',err);
                    vThis.$emit('switchLoading',false)
                  })
                }

            })
            .catch(err=>{
              console.log('error: ',err);
              vThis.$emit('switchLoading',false)
            })


        })
        .catch(err=>{
          console.log('error: ',err);
          vThis.$emit('switchLoading',false)
        })
    },


    executeEmit(payload){
      // console.log('executeEmit');
      this.$emit('accept', payload)
    },
    successfullPayment(request,tenant,landlord){
      // console.log('users/'+request.tenantId+'.json?auth='+this.$store.state.auth.idToken + {orderPaid:true});
      axios.patch('users/'+request.tenantId+'.json?auth='+this.$store.state.auth.idToken,{orderPaid:true})
      .then(()=>{
        // console.log('success');
      })
      .catch(err=>{
        console.log(err , "error with patch users in successfullPayment");
      })
      // console.log('bookingRequests/' + request.id + '.json?auth=' + this.$store.state.auth.idToken,{status:'Paid'});
      axios.patch('bookingRequests/' + request.id + '.json?auth=' + this.$store.state.auth.idToken,{status:'Paid'} )
      .then(()=>{
        // console.log('BR Patch');
      })
      .catch(err=>{
        console.log(err , "error with patch BR in successfullPayment");
      })

      // console.log('completedOrders/'+request.orderId+'.json?auth='+this.$store.state.auth.idToken,{payIn:true,status:'Paid'});
      axios.patch('completedOrders/'+request.orderId+'.json?auth='+this.$store.state.auth.idToken,{payIn:true,status:'Paid'})
      .then(()=>{
        this.$emit('update:working',false)
        if (landlord.sendChinese){
          // console.log('send email in zh to ll, ');
          AWSSES.emailing(request.landlord, 'ZHLLPaymentReceived')
          AWSSES.emailing(request.tenant, 'TPaymentReceivedZHLL')
        } else {
          // console.log('send email in english to ll');
          AWSSES.emailing(request.landlord, 'LLPaymentReceived')
          AWSSES.emailing(request.tenant, 'TPaymentReceivedENLL')
        }
        // console.log('Send email to tenant');
      })
    },
    failedPayment(request, failed){
      this.$emit('update:working',false)
      // AWSSES.emailing(this.admin, 'APaymentFailed')
      AWSSES.emailing(request.tenant, 'TPaymentFailed')
      axios.patch('completedOrders/'+request.orderId+'.json?auth='+this.$store.state.auth.idToken,{payIn:false,status:'Failed Payment',data:failed})
      .then(()=>{
      })
    },
    totalRent(rent){
      let tMod = moment(rent.contractDates.mod).add(1, 'days')
      let months = tMod.diff(moment(rent.contractDates.mid), 'months')
      let tMid = moment(rent.contractDates.mid).add(months, 'months')
      let days = tMod.diff(moment(tMid), 'days')
      // console.log(months, 'month', days, 'days');
      let DiM = moment(rent.contractDates.mod).daysInMonth()
      let utilities=0
      if (rent.utilities && rent.utilities>0){
        utilities=parseInt(rent.utilities)
      }

      // console.log(months * parseInt(contract.price) + (days / DiM) * parseInt(contract.price), 'is the total amout');
      return months * (parseInt(rent.price)+utilities) + (days / DiM) * (parseInt(rent.price)+utilitiess)
    },
    calcTotal(req){
      if (req.allRent==true){
        let roomPrice=new Number(req.price)
        let totalRent=totalRent(req)
        let discount=0
        if (req.discount && Number(req.discount)>0){
          discount=req.discount
        }
        let utilities=0
        if (req.utilities && req.utilities>0){
          utilities=parseInt(req.utilities)
        }
        let sf=Math.ceil((roomPrice+utilities) * sf)-discount
        let grandTotal=(totalRent+sf)*(1+this.transactionCost)
        return {total:grandTotal, commission:sf}
      } else {
        let roomPrice = new Number(req.price)
        // console.log(this.fxPrice)
        let tc=this.transactionCost
        let utilities=0
        if (req.utilities && req.utilities>0){
          utilities=req.utilities
        }
        let discount=0
        if (req.discount && Number(req.discount)>0){
          discount=req.discount
        }
        let sf=this.serviceFee
        req.commission = Math.ceil((( (roomPrice+ utilities) * sf) + roomPrice + utilities - discount ) * tc + ((roomPrice+utilities) * sf))
        req.total = roomPrice + req.commission - discount + utilities
        // console.log(req.total);
        return {total: req.total, commission:req.commission-discount}
      }
    }
  },
  computed:{
    nationality:function(){
      countries.registerLocale(require("i18n-iso-countries/langs/"+this.$i18n.locale+".json"));
      return countries.getName(this.tenant.nationality, this.$i18n.locale)
    },
    occupation:function(){
      if(this.tenant.occupation!=undefined && this.tenant.occupation.toLowerCase()=="student"){
        return this.$t("otherTenants.student")
      } else {
        return this.$t("otherTenants.worker")
      }
    }
  },
  created() {
    // console.log('create req');
    let vThis = this
    axios.get('/users/' + this.request.tenantId + '.json')
      .then(res => {
        vThis.tenant = res.data
        vThis.age = moment().diff(moment(vThis.tenant.birthday), 'years')
      })
      .catch(err=>{
        console.log('error: ',err);
      })

    vThis.end=moment.tz(vThis.request.dateRequested, moment.tz.guess()).add(2,'d')
    vThis.end = vThis.end.locale('en')
    vThis.end = vThis.end.format('llll')
    vThis.mid = moment(vThis.request.dates.mid).format('ll')
    vThis.mod = moment(vThis.request.dates.mod).format('ll')
    vThis.realMid = moment(vThis.request.realDates.mid).format('ll')
    vThis.realMod = moment(vThis.request.realDates.mod).format('ll')
    if (vThis.request.status.toLowerCase() != "requested") {
      // console.log(vThis.request.status)
      vThis.disabled = true
    }
    if (vThis.request.type.toLowerCase() == 'studio') {
      vThis.db = '/studios/'
    } else if (vThis.request.type.toLowerCase() == 'room') {
      vThis.db = '/rooms/'
    } else if (vThis.request.type.toLowerCase()== 'apartment'){
      vThis.db = '/wholeApts/'
    }
    axios.get(vThis.db + vThis.request.listingId + '.json')
      .then(res => {
        // console.log('got the room',res.data.type);
        vThis.room = res.data
        if (vThis.room.visual.hasOwnProperty('photos')){
          vThis.thumbnail = vThis.room.visual.photos[0]
        }
        if (vThis.request.type == 'room'){
          axios.get('/apartments/' + vThis.room.apartmentId + '.json')
          .then(res => {
            // console.log('got the apt');
            vThis.apartment = res.data
            if (vThis.room.general.fullRoomNumber==undefined){
              vThis.room.general.fullRoomNumber=vThis.apartment.general.name + ' ' + vThis.room.general.roomNumber
            }
          })
          .catch(err=>{
            console.log('error: ',err);
          })

        } else {
          vThis.apartment=this.room
        }
      })
      .catch(err=>{
        console.log('error: ',err);
      })

  },
  components: {
    Countdown,
    DeclineDialog
  }
}
</script>
<style lang="scss" scoped>
.card-border {}
.v-card-content {
    color: rgba(0,0,0,.60);
}

.no-shadow {
    box-shadow: none;
    border-bottom: solid thin rgba(0,0,0,.38);
}
.pic-overflow {
    overflow: hidden;
    height: 90px;
}
.pic-overflow img {
    padding-right: 10px;
}
.md-title {
    margin-bottom: 0;
}
.rush {
    color: #B00020;
}
.day {
    color: rgba(0,0,0,.87);
}
.dates {
    color: rgba(0,0,0,.87);
}
.deadline-time {
    font-size: 1.25em;
}
.justify{
  text-align:justify;
}
@media only screen and (max-width: 960px) {
    .md-button {
        font-size: 0.875em;
    }
    .deadline-time {
        font-size: 2rem;
        color: rgba(0,0,0,.87);
        line-height: 3rem;
    }
    .occupation {
        margin: 0.5em 0;
    }
    .tiny-title {
        color: rgba(0,0,0,.87);
    }
}

@media only screen and (min-width: 960px) {
    .hide-on-large {
        display: none;
    }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #00bfff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
</style>
