<template>
<div id="">
  <Navbar></Navbar>
  <v-container grid-list-md>
    <Loading v-if="initialLoad"></Loading>

    <v-layout  wrap v-else>
      <v-flex xs12>
        <h1 class="heading">{{$t("requestsInbox.bookingRequests")}} ({{currentBR.length}})</h1>
      </v-flex>
      <v-flex xs12 v-if="allBR.length==50" class=" text-xs-right">

        <small >(only 50 most recent are loaded) <a @click="loadAllBr()">(load all)</a></small>
      </v-flex>
    </v-layout>
    <v-tabs v-model="tab" color="primary" dark grow show-arrows>
      <v-tabs-slider color="white"></v-tabs-slider>

      <v-tab v-for="item in tabs" :key="item.val">
        {{item.text}}
      </v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item key="currentBR">
          <v-container grid-list-xs,sm,md,lg,xl>

            <v-layout  wrap v-if="currentBR.length>0">

              <bookingRequestItem v-for="(request,index) in currentBR" :rooms="rooms" :index="index" :key="request.id" :request="request" @accept="accept" :working.sync="working" @decline="decline"></bookingRequestItem>

            </v-layout>
            <v-layout  wrap justify-center v-else>
              <v-card>
                <v-layout  justify-center wrap>

                  <v-icon large justify-center class="top-padding-only pa-3 ">done_all</v-icon>
                </v-layout>
                <v-card-title primary-title>
                  {{$t("requestsInbox.noCurrentTitle")}}
                </v-card-title>
                <v-card-text>
                  {{$t("requestsInbox.noCurrentText1")}}
                  <br>{{$t("requestsInbox.noCurrentText2")}}
                  <br>
                  <br> {{$t("requestsInbox.noCurrentText3")}}
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" href='/addListing'>{{$t("requestsInbox.addListing")}}</v-btn>
                </v-card-actions>
              </v-card>
            </v-layout>
          </v-container>
        </v-tab-item>
        <v-tab-item key="acceptedBR">
          <v-container grid-list-xs,sm,md,lg,xl>

            <v-layout  wrap v-if=" acceptedBR.length>0">

                <bookingRequestItem v-for="(request,index) in acceptedBR" :index="index" :key="request.id" :request="request" @accept="accept" @decline="decline"></bookingRequestItem>

            </v-layout>


            <v-layout  wrap justify-center v-else>
              <v-card>
                <v-layout  justify-center wrap>

                  <v-icon large justify-center class="top-padding-only pa-3 ">highlight_off</v-icon>
                </v-layout>
                <v-card-title primary-title>
                  {{$t("requestsInbox.noAcceptedTitle")}}
                </v-card-title>
                <v-card-text>
                  {{$t("requestsInbox.noAcceptedText1")}}
                  <br>{{$t("requestsInbox.noCurrentText2")}}
                  <br>
                  <br> {{$t("requestsInbox.noCurrentText3")}}
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" href="/addListing">{{$t("requestsInbox.addListing")}}</v-btn>
                </v-card-actions>
              </v-card>
            </v-layout>
          </v-container>
        </v-tab-item>
        <v-tab-item key="pastBR">
          <v-container grid-list-xs,sm,md,lg,xl>
            <v-layout  wrap v-if="pastBR.length>0">

              <bookingRequestItem v-for="(request,index) in pastBR" :index="index" :key="index" :request="request" @accept="accept"  @decline="decline"></bookingRequestItem>
            </v-layout>
            <v-layout  wrap justify-center v-else>
              <v-card>
                <v-layout  justify-center wrap>

                  <v-icon large justify-center class="top-padding-only pa-3 ">pan_tool</v-icon>
                </v-layout>
                <v-card-title primary-title>
                  {{$t("requestsInbox.noPastTitle")}}
                </v-card-title>
                <v-card-text>
                  {{$t("requestsInbox.noPastText1")}}
                  <br>{{$t("requestsInbox.noPastText2")}}
                  <br>
                  <br> {{$t("requestsInbox.noCurrentText3")}}
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" href="/addListing">{{$t("requestsInbox.addListing")}}</v-btn>
                </v-card-actions>
              </v-card>
            </v-layout>

          </v-container>
        </v-tab-item>
        <v-tab-item key="declinedBR">
          <v-container grid-list-xs,sm,md,lg,xl>
            <v-layout  wrap v-if="declinedBR.length>0">

              <bookingRequestItem v-for="(request,index) in declinedBR" :index="index" :key="index" :request="request" @accept="accept" @decline="decline"></bookingRequestItem>
            </v-layout>
            <v-layout  wrap justify-center v-else>
              <v-card>
                <v-layout  justify-center wrap>

                  <v-icon large justify-center class="top-padding-only pa-3 ">delete_forever</v-icon>
                </v-layout>
                <v-card-title primary-title>
                  {{$t("requestsInbox.noDeclinedTitle")}}
                </v-card-title>
                <v-card-text>
                  {{$t("requestsInbox.noDeclinedText1")}}
                  <br>{{$t("requestsInbox.noCurrentText2")}}
                  <br>
                  <br> {{$t("requestsInbox.noCurrentText3")}}
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" href="/addListing">{{$t("requestsInbox.addListing")}}</v-btn>
                </v-card-actions>
              </v-card>
            </v-layout>

          </v-container>
        </v-tab-item>
        <v-tab-item key="canceledBR">
          <v-container grid-list-xs,sm,md,lg,xl>
            <v-layout  wrap v-if="canceledBR.length>0">
              <bookingRequestItem v-for="(request,index) in canceledBR" :index="index" :key="index" :request="request" @accept="accept" @decline="decline"></bookingRequestItem>
            </v-layout>
            <v-layout  wrap justify-center v-else>
              <v-card>
                <v-layout  justify-center wrap>

                  <v-icon large justify-center class="top-padding-only pa-3 ">check_circle</v-icon>
                </v-layout>
                <v-card-title primary-title>
                  {{$t("requestsInbox.noCanceledTitle")}}
                </v-card-title>
                <v-card-text>
                  {{$t("requestsInbox.noCanceledText1")}}
                  <br>{{$t("requestsInbox.noCurrentText2")}}
                  <br>
                  <br>{{$t("requestsInbox.noCurrentText3")}}
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" href="/addListing">{{$t("requestsInbox.addListing")}}</v-btn>
                </v-card-actions>
              </v-card>
            </v-layout>

          </v-container>
        </v-tab-item>

      </v-tabs-items>

    </v-tabs>
  </v-container>
</div>
</template>
<script>
import {
  mapGetters,
  mapActions,
} from 'vuex'
import Loading from '../shared/Loading.vue'
import moment from 'moment'
import axios from 'axios'
import * as types from '../../store/types'
import BookingRequestItem from './BookingRequestItem.vue'
import firebase from '../../FirebaseApp'

export default {
  name: "",
  data: () => ({
    tab: null,
    showCurrent: false,
    initialLoad: true,
    showPast: false,
    userId:null,
    showAccepted: false,
    showDeclined: false,
    brs:{},
    working:false,
    showCanceled: false,
    currentBR: [],
    pastBR: [],
    declinedBR: [],
    acceptedBR: [],
    allBR: [],
    rooms: [],
    canceledBR: [],
    countTBP: 0,

  }),
  firebase() {
    return {
      allRooms: {
        source: firebase.database().ref('rooms'),
        asObject: true,
        readyCallback: function() {
          // console.log('gotRooms');
          if (this.allRooms!=undefined && this.allRooms!=null ) {
            if ( this.allRooms.hasOwnProperty('.key')){
              delete this.allRooms['.key']
            }
            for (var room in this.allRooms) {
              if (this.allRooms.hasOwnProperty(room)) {
                for (var apartment in this.user.apartments) {
                  if (this.user.apartments.hasOwnProperty(apartment)) {
                    if (this.user.apartments[apartment].apartmentId == this.allRooms[room].apartmentId) {
                      this.allRooms[room].id = room
                      this.allRooms[room].type = 'room'
                      if (this.allRooms[room].draft==false){
                        this.rooms.push(this.allRooms[room])
                      }
                    }
                  }
                }
              }
            }
          }
        },
      },
      studios: {
        source: firebase.database().ref('studios').orderByChild('general/landlordId').equalTo(this.user.id),
        asObject: true,
        readyCallback: function() {
          // console.log('gotStudios');

          if (this.studios!=undefined && this.studios!=null){
            if (this.studios.hasOwnProperty('.key')){
              delete this.studios['.key']
            }
            for (var id in this.studios) {
              if (this.studios.hasOwnProperty(id)) {
                this.studios[id].id = id
                this.studios[id].type = 'studio'
                if (this.studios[id].draft==false){
                  this.rooms.push(this.studios[id])
                }
              }
            }
          }
        }
      },
      wholeApts: {
        source: firebase.database().ref('wholeApts').orderByChild('general/landlordId').equalTo(this.user.id),
        asObject: true,
        readyCallback: function() {
          // console.log('gotWholeApts', this.wholeApts);

          if (this.wholeApts!=undefined && this.wholeApts!=null){
            if (this.wholeApts.hasOwnProperty('.key')){
              delete this.wholeApts['.key']
            }
            for (var id in this.wholeApts) {
              if (this.wholeApts.hasOwnProperty(id)) {
                this.wholeApts[id].id = id
                this.wholeApts[id].type = 'studio'
                if (this.wholeApts[id].draft==false){
                  this.rooms.push(this.wholeApts[id])
                }
              }
            }

          }
        }
      },
      // brs: {
      //   source: firebase.database().ref('bookingRequests').orderByChild('landlordId').equalTo(this.userId),
      //   readyCallback: function() {
      //     this.orderRequests(this.brs)
      //   }
      // }
    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    tabs() {
      let t = [{
          text: this.$t('requestsInbox.current'),
          val: 'currentBR'
        },
        {
          text: this.$t('requestsInbox.accepted'),
          val: 'acceptedBR'
        },
        {
          text: this.$t('requestsInbox.past'),
          val: 'pastBR'
        },
        {
          text: this.$t('requestsInbox.declined'),
          val: 'declinedBR',
        },
        {
          text: this.$t('requestsInbox.canceled'),
          val: 'canceledBR'
        }
      ]
      return t
    }
  },
  methods: {
    ...mapActions({
      refreshUser: types.FETCH_USER
    }),
    loadAllBr(){
      this.initialLoad=true
      axios.get('bookingRequests.json?orderBy="landlordId"&equalTo="' + this.userId +'"')
      .then(res=>{
        this.brs=res.data
        this.orderRequests(this.brs)
        console.log('got the orders', Object.keys(this.brs).length);
      })
    },
    accept(payload) {
      // console.log('Accepted');
      this.currentBR.splice(payload.index, 1)
      this.acceptedBR.unshift(payload.request)
      this.working=false
      // console.log(this.working);
    },
    decline(payload) {
      this.currentBR.splice(payload.index, 1)
      this.declinedBR.unshift(payload.request)
    },
    orderRequests(brs){
      console.log('ordering')
      // let newRequest = res.data
      // console.log()
      this.allBR=[]
      this.currentBR=[]
      this.declinedBR=[]
      this.acceptedBR=[]
      this.pastBR=[]
      this.canceledBR=[]
      for (var i in brs) {
        if (brs.hasOwnProperty(i)) {
          let cbr=brs[i]
          cbr.id=i
          cbr['.key']=i

      // for (var i = 0; i < brs.length; i++) {
      //   let cbr=brs[i]
          this.allBR.unshift(cbr)
          if (cbr.status == "Declined") {
            this.declinedBR.unshift(cbr)
          } else if (cbr.status == "Accepted" || cbr.status=="Paid" || cbr.status=="AdminMade") {
            this.acceptedBR.unshift(cbr)

          } else if (cbr.status == "Past") {
            // console.log('status is past')
            this.pastBR.unshift(cbr)
          } else if (cbr.status == "Requested" && moment(cbr.dateRequested).add(2, 'd').isBefore(moment())) {
            // console.log('patching the DB')
            // console.log(cbr)
            this.pastBR.unshift(cbr)
            axios.patch('bookingRequests/' + cbr.id + '.json' + '?auth=' + this.$store.state.auth.idToken, {
              status: 'Past'
            })

            .then(() => {
              console.log('patched!')
            })
            .catch(err => {
              console.log(err)
            })
          } else if (cbr.status == "Canceled") {
            this.canceledBR.unshift(cbr)
          } else {
            this.currentBR.unshift(cbr)
            // console.log('current')
          }
        }
      }
      console.log('loaded');
      this.initialLoad=false

    },
    // getRequests(brIns){
    //   // console.log(brIns, 'brins number of br');
    //   for (var request in brIns) {
    //     if (brIns.hasOwnProperty(request)) {
    //       // console.log(brIns[request].bookingRequestId);
    //       axios.get('bookingRequests/' + brIns[request].bookingRequestId + '.json?')
    //         .then(res => {
    //           // console.log('request:',res.data)
    //           this.initialLoad = false
    //           // console.log(res)
    //           let newRequest = res.data
    //           // console.log()
    //           if (res.data!=null && res.data!=undefined){
    //             this.allBR.unshift(newRequest)
    //             if (res.data.status == "Declined") {
    //               this.declinedBR.unshift(newRequest)
    //             } else if (res.data.status == "Accepted" || res.data.status=="Paid"|| res.data.status=="AdminMade") {
    //               this.acceptedBR.unshift(newRequest)
    //
    //             } else if (res.data.status == "Past") {
    //               // console.log('status is past')
    //               this.pastBR.unshift(newRequest)
    //             } else if (res.data.status == "Requested" && moment(res.data.dateRequested).add(2, 'd').isBefore(moment())) {
    //               // console.log('patching the DB')
    //               // console.log(newRequest)
    //               this.pastBR.unshift(newRequest)
    //               axios.patch('bookingRequests/' + newRequest.id + '.json' + '?auth=' + this.$store.state.auth.idToken, {
    //                 status: 'Past'
    //               })
    //
    //               .then(() => {
    //                 console.log('patched!')
    //               })
    //               .catch(err => {
    //                 console.log(err)
    //               })
    //             } else if (res.data.status == "Canceled") {
    //               this.canceledBR.unshift(newRequest)
    //             } else {
    //               this.currentBR.unshift(newRequest)
    //               // console.log('current')
    //             }
    //
    //           } else {
    //             // console.log(res, 'the res from the null/undefined request');
    //           }
    //
    //         })
    //         .catch(err=>{
    //           console.log('error: ',err);
    //         })
    //
    //     }
    //   }
    //
    // }
  },

  created() {
    console.log('this.user.bookingRequestsIn ', this.user.bookingRequestsIn)
    if (this.user.bookingRequestsIn == undefined || this.user.bookingRequestsIn == {}) {
      this.initialLoad = false
    }
    axios.patch('users/' + this.user.id + '.json' + '?auth=' + this.$store.state.auth.idToken, {
        tbp: 0
      })
      .then(() => {
        // this.refreshUser()
      })
      .catch(err=>{
        console.log('error: ',err);
      })
    // console.log(this.$route.query.id, 'the query');
    if(this.$route.query.id!=undefined){
      // console.log();
      this.userId=this.$route.query.id

      axios.get('users/'+this.$route.query.id+'.json')
      .then(res=>{

        // this.getRequests(res.data.bookingRequestsIn)
      })
    } else {
      this.userId=this.user.id
      if (this.user.bookingRequestsIn!=undefined && this.user.bookingRequestsIn!=null){
        // console.log('getRequests');
        // this.getRequests(this.user.bookingRequestsIn)

      } else {
        // console.log('No Booking Requests');
      }
    }
    axios.get('bookingRequests.json?orderBy="landlordId"&equalTo="' + this.userId + '"&limitToLast=50')
    .then(res=>{
      this.brs=res.data
      this.orderRequests(this.brs)
      console.log('got the orders', Object.keys(this.brs).length);
    })
  },
  components: {
    BookingRequestItem,
    Loading,
  }
}
</script>
<style lang="scss" scoped>
.titlebar {
    border-bottom: solid rgba(0,0,0,.38) thin;
}
.top-bar {
    margin: 10px;

}
.inside-bar {
    padding-left: 16px;
    border-bottom: thin solid rgba(0,0,0,.38);
}
.see-more {
    cursor: pointer;
}
.active {
    background-color: rgba(0, 191, 255,.09);
}
</style>
